<script lang="ts">
// import { useConfigStore } from '~/store'

export default {
    props : {
        color : { type : String, required : false, default : "light" },
    },
}
</script>

<template>
    <v-footer
        class="border-t"
        :color="color"
        style="z-index:50; position: inherit;"
    >
        <div class="w-100 align-center">
            <v-row class="ma-0">
                <v-col class="py-1">
                    <a href="https://www.geoplateforme17.fr/" target="_blank">
                        <v-btn variant="text">
                            © {{ new Date().getFullYear() }}
                            Géoplateforme17
                        </v-btn>
                    </a>
                </v-col>

             <v-col :class="$vuetify.display.smAndUp && 'text-right'" class="py-1">
            <div>
                <NuxtLink to="/mentions-legales">
                    <v-btn variant="text">
                        Mentions légales
                    </v-btn>
                </NuxtLink>
                <v-divider class="border-opacity-100" vertical />
                <NuxtLink to="/donnees-personnelles">
                    <v-btn variant="text">
                        Données personnelles
                    </v-btn>
                </NuxtLink>
            </div>
        </v-col>
        </v-row>

        </div>
    </v-footer>
</template>
